module.exports = new Promise(async (resolve, reject) => {
    const hostname = window.location.hostname;
    const waitForInitialization = () => {
      return new Promise(resolve => {
        if (window.hivelight?.initializing) {
          setTimeout(() => {
            resolve(waitForInitialization())
          }, 100)
        } else {
          resolve()
        }
      })
    }

    if (!window.hivelight) {
      window.hivelight = {
        initializing: true
      }
    } else {
      await waitForInitialization();
    }

    let {versions} = window.hivelight || {}
    if (!versions) {
      const getVersions = async (attempt = 0) => {
        try {
          const response = await fetch(
            "https://" +
            (hostname === "localhost" ? "app-development.hivelight.com" : hostname) +
            "/v1/configurations/versions"
          );
          return response.json();
        } catch(e) {
          // console.error(e)
          // console.log('error getting versions', e)
          if (attempt <= 3) {
            return getVersions(attempt+1);
          } else {
            console.log('attempts', attempt)
            return []
          }
        }
      };

      versions = await getVersions();
      window.hivelight = {
        ...window.hivelight,
        versions
      }
    }

    let remoteUrlWithVersion = "/remote-components/dashboard/remoteEntry.js";
    if (!versions || hostname !== "localhost") {
      const version = versions.find(version => version.name.includes("dashboard"))?.version
      if (version && version !== "main") {
        remoteUrlWithVersion = "/remote-components/dashboard/" + version + "/remoteEntry.js";
      }
    }

    const script = document.createElement('script');
    script.src = remoteUrlWithVersion;
    script.onerror = (event) => {
      console.log("Script failed to load:", remoteUrlWithVersion);
      console.log(event);
      reject(event);
    };
    script.onload = () => {
      // the injected script has loaded and is available on window
      // we can now resolve this Promise
      const proxy = {
        get: (request) => window.dashboard.get(request),
        init: (arg) => {
          try {
            return window.dashboard.init(arg)
          } catch(e) {
            console.log('remote container %s already initialized', dashboard)
          }
        }
      } 
      resolve(proxy)
    }
    // inject this script with the src set to the versioned remoteEntry.js
    document.head.appendChild(script);
    window.hivelight.initializing = false;
  })
  ;